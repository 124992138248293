<template>
  <div class="projects mb-4">
    <header class="projects-header">
      <TitleSection
        :title="'Aperturas y Cierres de Boleterías'"
        :subtitle="
          'Gestiona las aperturas y cierres de las boleterias en el sistema'
        "
      />
    </header>
    <div class="row py-4">
      <div class="col-lg-3 col-md-3 col-sm-12 pb-2 pt-0">
        <v-text-field
          label="Fecha fin de asignación"
          type="date"
          :rounded="rounded"
          :color="color"
          :dark="dark"
          :background-color="bgColor"
          :rules="required"
          :disabled="disabled"
          :hide-details="details"
          :outlined="outlined"
          :filled="filled"
          :dense="dense"
          :autocomplete="autocomplete"
          single-line
          v-model="assignment.date"
        >
        </v-text-field>
      </div>
      <div class="col-lg-2 col-md-3 col-sm-12 pb-2 pt-0">
        <v-btn
          type="submit"
          :loading="loading"
          :disabled="loading"
          tile
          dark
          color="primary"
          depressed
          class="h-100"
          @click="getAssignment"
          ><v-icon left dark> mdi-magnify </v-icon>
          Buscar
        </v-btn>
      </div>
    </div>

    <TableOpenCloseBoxOffices
      :assignments="assignments"
      :loadingDelete="loadingDelete"
      :isToday="assignment.date === today"
      @openDelete="removeAssignment"
    >
    </TableOpenCloseBoxOffices>
  </div>
</template>

<script>
import TitleSection from "@/components/titles/TitleSection";
import TableOpenCloseBoxOffices from "@/components/tables/TableOpenCloseBoxOffices";
import { mapActions } from "vuex";
import Swal from "sweetalert2";
import moment from "moment-timezone";
import axios from "@/api/entradaslima";
import store from "../../store";
export default {
  components: {
    TitleSection,
    TableOpenCloseBoxOffices
  },
  data() {
    return {
      rounded: false,
      color: "#738297",
      bgColor: "#ffffff",
      required: [v => !!v || "Requerido"],
      upToZero: [v => (!!v && v > 0) || "Incorrecto"],
      disabled: false,
      details: true,
      filled: true,
      outlined: false,
      dense: true,
      autocomplete: "off",
      readonly: true,
      dark: false,
      dialog: false,
      loadingDelete: -1,
      loading: false,
      assignment: {
        boxOffice: {},
        date: moment()
          .tz("America/Lima")
          .format("YYYY-MM-DD")
      },
      idParque: store.getters.getSessionParqueId,
      usuario: store.getters.getSessionUser.CORREO,
      assignments: [],
      boxOffices: [],
      users: [],
      today: moment()
        .tz("America/Lima")
        .format("YYYY-MM-DD")
    };
  },
  computed: {
    getTitle() {
      return "Nueva Asignación";
    },
    getSubtitle() {
      return "Rellena todos los campos para crear una nueva asignación";
    }
  },
  async mounted() {
    await this.getAssignment();
  },
  methods: {
    ...mapActions({
      generateConfirmDialog: "generateConfirmDialog",
      generateSnackBarMessage: "generateSnackBarMessage"
    }),
    async getAssignment() {
      try {
        this.loading = true;
        const { data } = await axios.get(
          `/parques/${this.idParque}/asignaciones/estados?fecha=${this.assignment.date}`
        );
        this.loading = false;
        this.assignments = data.data;
      } catch (error) {
        this.loading = false;
        return Swal.fire({
          icon: "error",
          text: error.response.data.message || "Ocurrió un error interno"
        });
      }
    },
    async removeAssignment(dto) {
      const response = await this.generateConfirmDialog({
        message: `¿Esta seguro de eliminar el estado de ${
          dto.TIPOESTADO == "AI" ? "Apertura" : "Cierre"
        }?`,
        confirmMessage: "Si, quiero eliminar"
      });
      if (response) {
        try {
          this.loadingDelete = dto.IDESTADOBOLETERIA;
          const { data } = await axios.delete(
            `/parques/${this.idParque}/asignaciones/${dto.IDASIGNACION}/estados/${dto.IDESTADOBOLETERIA}`,
            {
              data: {
                usuario: this.usuario
              }
            }
          );
          this.loadingDelete = -1;
          this.generateSnackBarMessage({
            message: data.message
          });
          await this.getAssignment();
        } catch (error) {
          this.loadingDelete = -1;
          return Swal.fire({
            icon: "error",
            text: error.response.data.message || "Ocurrió un error interno"
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
